<template lang="html">
  <v-form ref="form" lazy-validation>
    <v-card>
      <v-card-title>
        {{ $tc('label.usuario_interno', 1) }}
      </v-card-title>
      <v-container grid-list-xl fluid>
        <v-row>
          <v-col cols="12" sm="4" md="4" slot="antes">
            <v-text-field
              :label="`${$t('label.email')} *`"
              ref="email"
              v-model="usuario.email"
              required
              :rules="[rules.requiredText, rules.isEmailValid]"
              maxlength="150"
              clearable
              counter
              :disabled="somenteLeitura || (isAmbienteMagalu && !isNovoCadastro && !campoEmailVazio) || encontrouOmni"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="4" md="4" slot="antes">
            <v-btn
              id="btn_buscar_usario_omni"
              :disabled="somenteLeitura || usuario.ehEdicao() || encontrouOmni"
              @click.native="buscarUsuarioOmni">
              {{ $t('label.buscar') }}
            </v-btn>
            <v-btn
              id="btn_limpar_busca"
              class="ml-3"
              :disabled="somenteLeitura || usuario.ehEdicao()"
              @click.native="limparFormulario">
              {{ $t('label.limpar') }}
            </v-btn>
          </v-col>
        </v-row>
        <metadados-container-layout
          v-if="metadadosUsuario"
          :metadados-entidade="metadadosUsuario"
          :objeto="usuario"
          :input-layout="inputLayout"
          :layout-class="layoutClass"
          :somente-leitura="somenteLeitura"
          ref="containerUsuario">
          <v-col cols="12" sm="4" md="4" slot="antes"
            v-if="cadastroFuncionario">
            <v-text-field
              :label="$t('label.matricula')"
              v-model="usuario.matricula"
              maxlength="50"
              counter
              :disabled="somenteLeitura || (isAmbienteMagalu && !isNovoCadastro)"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="4" md="4" slot="antes">
            <v-text-field
              :label="`${$t('label.login')} *`"
              v-model="usuario.nomUsuario"
              required
              :rules="[rules.requiredText]"
              maxlength="100"
              counter
              :disabled="somenteLeitura || !isNovoCadastro"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="4" md="4" slot="antes">
            <v-autocomplete
              id="usuario-perfil"
              class="custom-autocomplete"
              :disabled="somenteLeitura || (isAmbienteMagalu && !isNovoCadastro)"
              required
              :rules="[rules.required]"
              :no-data-text="$tc('message.nenhum_registro', 1)"
              :label="`${$tc('label.perfil', 1)} *`"
              v-model="usuario.idPerfil"
              :items="perfis"
              item-text="nomPerfil"
              item-value="id"
              clearable>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="4" md="4" slot="antes">
            <v-text-field
              :label="`${$t('label.nome')} *`"
              v-model="usuario.nome"
              required
              :rules="[rules.requiredText]"
              maxlength="100"
              counter
              :disabled="somenteLeitura || (isAmbienteMagalu && !isNovoCadastro && !campoNomeVazio)"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="4" md="4" slot="antes">
            <v-text-field
              :label="$t('label.sobrenome')"
              v-model="usuario.sobrenome"
              maxlength="150"
              counter
              :disabled="somenteLeitura || (isAmbienteMagalu && !isNovoCadastro)"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="4" md="4" slot="antes"
            v-if="cadastroFuncionario">
            <v-text-field
              :label="$t('label.cpf')"
              v-model="usuario.cpf"
              :disabled="somenteLeitura"
              v-mask="'###.###.###-##'"
              :return-masked-value="true"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="4" md="4" slot="antes"
            v-if="cadastroFuncionario">
            <v-autocomplete
              id="usuario-estruturausuario"
              class="custom-autocomplete"
              :disabled="somenteLeitura"
              required
              :rules="[rules.required]"
              :no-data-text="$tc('message.nenhum_registro', 1)"
              :label="`${$tc('label.estrutura_usuario', 1)} * `"
              v-model="usuario.idEstruturaUsuario"
              :items="estruturasUsuario"
              item-text="nomEstruturaUsuario"
              item-value="id"
              :search-input.sync="searchInputEstruturaUsuario"
              @click:append="triggerEstruturaUsuario"
              @click.native="clickEstruturaUsuario"
              @input="estruturaUsuarioSelecionada"
              clearable>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="4" md="4" slot="antes"
            v-if="cadastroFuncionario">
            <v-autocomplete
              id="usuario-superioresimediatos"
              class="custom-autocomplete"
              :disabled="!usuario.idEstruturaUsuario || somenteLeitura"
              :no-data-text="$tc('message.nenhum_registro', 1)"
              :label="$t('label.superior_imediato')"
              v-model="usuario.superioresImediatos"
              :items="superioresImediato"
              item-text="nomeCompleto"
              item-value="id"
              :search-input.sync="searchInputSuperiorImediato"
              @click:append="triggerSuperiorImediato"
              @click.native="clickSuperiorImediato"
              multiple
              chips
              deletable-chips>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="4" md="4" slot="antes"
            v-if="cadastroFuncionario">
            <v-autocomplete
              id="usuario-divisao"
              class="custom-autocomplete"
              :disabled="!usuario.idEstruturaUsuario || somenteLeitura"
              required
              :rules="[rules.requiredTags]"
              :no-data-text="$tc('message.nenhum_registro', 1)"
              :label="`${$tc('label.divisao', 1)} * `"
              v-model="usuario.idDivisoes"
              :items="divisoes"
              item-text="nome"
              item-value="id"
              :search-input.sync="searchInputDivisao"
              @click:append="triggerDivisao"
              @click.native="clickDivisao"
              multiple
              chips
              deletable-chips
              @input="divisaoSelecionada"
              return-object>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="4" md="4" slot="antes"
            v-if="cadastroCliente">
            <v-autocomplete
              id="usuario-tipo-verba"
              class="custom-autocomplete"
              :disabled="somenteLeitura"
              :no-data-text="$tc('message.nenhum_registro', 1)"
              :label="`${$tc('label.tipo_verba', 1)}`"
              v-model="usuario.tiposVerba"
              :items="listaTiposVerba"
              item-text="nome"
              item-value="id"
              clearable
              multiple
              chips
              deletable-chips
              return-object>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="2" slot="depois">
            <v-switch
              color="primary"
              :label="$t('label.recebe_mail')"
              :disabled="somenteLeitura"
              v-model="usuario.indRecebeMail">
            </v-switch>
          </v-col>
          <v-col cols="12" sm="2" slot="depois">
            <v-switch
              color="primary"
              :label="$t('label.ativo')"
              :disabled="desabilitarFlagAtivo()"
              v-model="usuario.indAtivo">
            </v-switch>
          </v-col>
        </metadados-container-layout>

        <div v-if="cadastroCliente">
          <hr class="primary" style="margin: 40px 0" />
          <v-row
            v-if="!somenteLeitura">
            <v-col cols="12" sm="4">
              <v-autocomplete
                id="usuario-cliente"
                class="custom-autocomplete"
                required
                :no-data-text="$t('message.nenhum_registro')"
                :label="`${$tc('label.cliente', 1)} * `"
                v-model="usuarioCliente"
                :items="clientes"
                item-text="codNome"
                item-value="id"
                :search-input.sync="searchInputCliente"
                @click:append="triggerCliente"
                @click.native="clickCliente"
                return-object
                clearable>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" sm="4">
              <v-switch
                color="primary"
                :label="$t('label.participa_aprovacao_acordo')"
                v-model="indAprovadorAcordo"
              ></v-switch>
            </v-col>
            <v-col cols="12" sm="4">
              <v-btn class="accent"
                @click="adicionarCliente"
                :disabled="!usuarioClienteAdicionavel">
                {{ $t('label.adicionar') }}
              </v-btn>
            </v-col>
          </v-row>

          <v-data-table
            :headers="cabecalhoClientes"
            :items="usuario.clientes"
            :no-data-text="$t('message.nenhum_cliente_adicionado')"
            hide-default-footer
            disable-pagination
            :items-per-page="-1">
            <template v-slot:item.participa_aprovacao_acordo="{ item }">
              <v-switch
                color="primary"
                v-model="item.indAprovadorAcordo"
                :disabled="somenteLeitura"
              ></v-switch>
            </template>
            <template v-slot:item.indAtivo="{ item }">
              <v-switch
                color="primary"
                v-model="item.indAtivo"
                :disabled="somenteLeitura"
              ></v-switch>
            </template>
          </v-data-table>
        </div>
      </v-container>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn v-if="somenteLeitura" @click.native="cancel">{{ $t('label.voltar') }}</v-btn>
        <v-btn v-if="!somenteLeitura" @click.native="openCancelarDialog">{{ $t('label.cancelar') }}</v-btn>
        <v-btn v-if="!somenteLeitura" @click.native="openSaveDialog" color="primary">{{ $t('label.salvar') }}</v-btn>
      </v-card-actions>
    </v-card>

    <confirm
      ref="confirmDialog"
      :message="dialog.content"
      :persistent="true"
      @agree="save()">
    </confirm>

    <confirm
      ref="cancelarDialog" :title="$t('title.atencao')"
      :message="$t('message.tem_certeza_cancelar')"
      @agree="cancel">
    </confirm>

  </v-form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { montarEstruturaDinamica } from '../../common/functions/metadados';
import { generateComputed } from '../../common/functions/roles-computed-generator';
import { copyObject } from '../../common/functions/helpers';
import Usuario from './Usuario';
import Confirm from '../../shared-components/vuetify/dialog/Confirm';
import MetadadosContainerLayout from '../../shared-components/metadados/MetadadosContainerLayout';
import UsuarioFormArquivo from './UsuarioFormArquivo';
import UsuarioDataAlteracaoDialog from './UsuarioDataAlteracaoDialog';
import {
  forceHideLoading,
  forceLoading,
} from '../../common/functions/loading';

export default {
  name: 'UsuarioForm',
  props: {
    somenteLeitura: Boolean,
    default: false,
  },
  components: {
    Confirm,
    MetadadosContainerLayout,
    UsuarioFormArquivo,
    UsuarioDataAlteracaoDialog,
  },
  computed: {
    ...mapGetters('metadados', [
      'getUsuarioMetadado',
    ]),
    ...mapGetters('usuario', [
      'usuarioLogado',
    ]),
    ...mapGetters('roles', [
      'getAllRoles',
    ]),
    ...mapGetters('implantacao', [
      'indAmbienteProdutivo',
      'chaveEmpresa',
    ]),
    ...generateComputed('usr', [
      'canPost',
      'canEdit',
      'canSearch',
      'canAccessCRUD',
    ]),
    isNovoCadastro() {
      return !this.idUsuario;
    },
    cadastroFuncionario() {
      const ehCadastroFuncionario = this.usuario.tipo === 'FUNCIONARIO';

      if (ehCadastroFuncionario) {
        this.usuario.clientes = [];
      }

      return ehCadastroFuncionario;
    },
    campoNomeVazio() {
      return !this.usuario.nome || this.usuario.nome.trim() === '';
    },
    campoEmailVazio() {
      return !this.usuario.email || this.usuario.email.trim() === '';
    },
    usuarioLogadoFuncionario() {
      return this.usuarioLogado.tipo === 'FUNCIONARIO';
    },
    cadastroCliente() {
      const ehCadastroCliente = this.usuario.tipo === 'CLIENTE';

      if (ehCadastroCliente) {
        this.usuario.matricula = undefined;
        this.usuario.idEstruturaUsuario = undefined;
        this.usuario.superioresImediatos = [];
        this.usuario.idDivisoes = [];
      }

      return ehCadastroCliente;
    },
    metadadosUsuario() {
      if (this.getUsuarioMetadado && this.getUsuarioMetadado.mapaEntidades) {
        if (!this.isUsuarioFuncionario && this.getUsuarioMetadado.mapaEntidades.grupofornecedor) {
          delete this.getUsuarioMetadado.mapaEntidades.grupofornecedor;
          return this.getUsuarioMetadado;
        }
        return this.getUsuarioMetadado;
      }
      return undefined;
    },
    usuarioClienteAdicionavel() {
      return this.usuarioCliente
        && Object.keys(this.usuarioCliente).length > 0
        && this.usuarioCliente.id;
    },
    isUsuarioFuncionario() {
      return this.usuarioLogado.tipo === 'FUNCIONARIO';
    },
    isAmbienteMagalu() {
      return this.chaveEmpresa === 'MAGALU';
    },
    isAmbienteEpoca() {
      return this.chaveEmpresa === 'EPOCA';
    },
    isAmbienteKabum() {
      return this.chaveEmpresa === 'KABUM';
    },
  },
  data() {
    return {
      usuarioResource: this.$api.usuario(this.$resource),
      perfilResource: this.$api.perfil(this.$resource),
      estruturaUsuarioResource: this.$api.estruturaUsuario(this.$resource),
      divisaoResource: this.$api.divisao(this.$resource),
      clienteResource: this.$api.cliente(this.$resource),
      extensaoResources: this.$api.extensao(this.$resource),
      resourceMetadado: this.$api.metadado(this.$resource),
      configSistemaResource: this.$api.configuracaoSistema(this.$resource),
      searchInputEstruturaUsuario: null,
      searchInputSuperiorImediato: null,
      searchInputDivisao: null,
      searchInputCliente: '',
      dialog: {
        ref: 'dialog1',
        from: 'saveRegisterFromButton',
        title: this.$t('label.atencao'),
        content: '',
        action() {},
      },
      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
        requiredText: (value) => (!!value && !!value.trim()) || this.$t('message.campo_obrigatorio'),
        requiredTags: (value) => value.length > 0 || this.$t('message.campo_obrigatorio'),
        isEmailValid: (value) => this.emailReg.test(value) || this.$t('message.campo_obrigatorio_ou_invalido'),
      },
      perfis: [],
      estruturasUsuario: [],
      estruturaUsuario: null,
      superioresImediato: [],
      divisoes: [],
      clientes: [],
      listaTiposVerba: [],
      extensoes: [],
      niveisExtensoes: [],
      nivel: {},
      fluxoAprovacaoRepreLegal: false,

      usuario: new Usuario(null, 'FUNCIONARIO'),
      usuarioCliente: {},
      indAprovadorAcordo: false,

      inputLayout: {
        xs12: true,
        sm12: false,
        sm6: true,
        md3: false,
        md4: true,
        md6: false,
        md9: false,
        md12: false,
      },

      layoutClass: { wrap: true },

      cabecalhoClientes: [
        { text: this.$tc('label.cod_cliente', 1), sortable: true, value: 'idExterno' },
        { text: this.$tc('label.nome_cliente', 1), sortable: true, value: 'nomCliente' },
        { text: this.$t('label.participa_aprovacao_acordo'), value: 'participa_aprovacao_acordo', sortable: false },
        { text: this.$t('label.ativo'), value: 'indAtivo', sortable: false },
      ],
      quantidadeArquivos: 0,
      menuInicio: false,
      menuFim: false,
      emailReg: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      statusAprovacaoUsuario: '',
      justificativaAprovacaoReprovacaoUsuario: '',
      titleJustificativaModal: '',
      dataInicioVigenciaOriginal: '',
      dataFimVigenciaOriginal: '',
      index: 0,
      usuarioSemEdicao: {},
      encontrouOmni: false,
    };
  },
  watch: {
    searchInputEstruturaUsuario(val) {
      if (this.usuario && this.usuario.idEstruturaUsuario) {
        const selecao = this.estruturasUsuario
          .filter((est) => est.id === this.usuario.idEstruturaUsuario);
        if (selecao.length > 0 && selecao[0].nomEstruturaUsuario === val) {
          return;
        }
      }

      if (this.timeoutEstrutura) {
        window.clearTimeout(this.timeoutEstrutura);
      }
      this.timeoutEstrutura = window.setTimeout(() => {
        if (val != null) this.buscarEstruturasUsuario(val);
      }, 500);
    },
    searchInputSuperiorImediato(val) {
      if (this.timeoutSuperiorImediato) {
        window.clearTimeout(this.timeoutSuperiorImediato);
      }
      this.timeoutSuperiorImediato = window.setTimeout(() => {
        if (val != null) this.buscarSuperioresImediatos(val);
      }, 500);
    },
    searchInputDivisao(val) {
      if (this.timeoutDivisao) {
        window.clearTimeout(this.timeoutDivisao);
      }
      this.timeoutDivisao = window.setTimeout(() => {
        if (val != null) this.buscarDivisoes(val);
      }, 500);
    },
    searchInputCliente(val) {
      if (this.usuarioCliente && this.usuarioCliente.codNome) {
        const selecao = this.usuarioCliente.codNome;
        if (selecao === val) {
          return;
        }
      }

      if (this.timeoutCliente) {
        window.clearTimeout(this.timeoutCliente);
      }
      this.timeoutCliente = window.setTimeout(() => {
        if (val != null) this.buscarClientes(val);
      }, 500);
    },
  },
  methods: {
    copyObject,
    ...mapActions('metadados', [
      'setUsuarioMetadado',
    ]),
    buscarUsuarioOmni() {
      if (!this.$refs.email.validate(true)) return;

      const { tipo, email } = this.usuario;
      const filtro = { tipo, email };

      this.usuarioResource.buscarNoOmni(filtro)
        .then((res) => {
          const usuarioOmni = res.data;
          this.usuario.construirAPartirUsuarioOmni(usuarioOmni);
          const perfil = this.perfis.find((p) => p.idExterno === usuarioOmni.idPerfil);
          this.usuario.idPerfil = perfil ? perfil.id : null;
          this.encontrouOmni = true;
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    popularMetadadosUsuario() {
      if (this.getUsuarioMetadado) {
        return;
      }
      this.setUsuarioMetadado({
        resource: this.resourceMetadado.listarUsuario,
      });
    },
    limparFormulario() {
      this.usuario = new Usuario(null, 'FUNCIONARIO');
      this.encontrouOmni = false;
      this.$refs.form.reset();
      setTimeout(() => {
        this.usuario.indRecebeMail = true;
        this.usuario.indAtivo = true;
      }, 1e2);
    },
    cancel() {
      this.limparFormulario();
      this.$router.push({ name: 'usuario-funcionario' });
    },
    estruturaUsuarioSelecionada() {
      this.usuario.superioresImediatos = [];
      this.usuario.divisoes = [];
      this.usuario.idDivisoes = [];
    },
    adicionarCliente() {
      if (this.usuario.clientes.find((item) => item.id === this.usuarioCliente.id)) {
        this.$toast(this.$t('errors.cliente.ja_adicionado'));
        return;
      }
      this.usuarioCliente.indAtivo = true;
      this.usuarioCliente.indAprovadorAcordo = this.indAprovadorAcordo;
      this.usuario.clientes.push(this.usuarioCliente);
      this.usuarioCliente = {};
      this.indAprovadorAcordo = false;
    },
    openCancelarDialog() {
      this.$refs.cancelarDialog.open();
    },
    finalizar() {
      this.$refs.confirmDialog.open();
    },
    openSaveDialog(ref, from) {
      if (!this.$refs.form.validate()) return;
      const content = this.$t('message.salvar_usuario');
      this.setDialogContent(content, this.save, from || this.dialog.from);
      this.$refs.confirmDialog.open();
    },
    save(statusFluxo = null, dataAlterada = false) {
      this.aplicarTrimCamposTexto();
      const objArmazenamento = {
        ...this.usuario,
        ...this.$refs.containerUsuario.getValoresDependencias(),
      };
      objArmazenamento.statusFluxo = statusFluxo;
      objArmazenamento.justificativaAprovacao = this.justificativaAprovacaoReprovacaoUsuario;
      objArmazenamento.dataAlterada = dataAlterada || null;
      if (this.usuario.dtaInicioVigenciaPrevia) {
        objArmazenamento.dtaInicioVigenciaPrevia = this.usuario.dtaInicioVigenciaPrevia;
        objArmazenamento.dtaFimVigenciaPrevia = this.usuario.dtaFimVigenciaPrevia;
      }
      this.exibeAlertaLgpd();
      if (this.isNovoCadastro) {
        this.requestGravar(objArmazenamento, this.usuarioResource.gravar);
      } else {
        this.requestAtualizar(objArmazenamento, this.usuarioResource.atualizar);
      }
    },
    exibeAlertaLgpd() {
      if (this.indAmbienteProdutivo) return;

      const alertaLgpd = this.$t('message.lgpd');
      alert(alertaLgpd); // eslint-disable-line
    },
    aplicarTrimCamposTexto() {
      this.usuario.nomUsuario = this.usuario.nomUsuario.normalize('NFD').replace(/[\u0300-\u036f]/g, '').trim();
      this.usuario.email = this.usuario.email.trim();
      this.usuario.nome = this.usuario.nome.trim();
      if (this.usuario.matricula) {
        this.usuario.matricula = this.usuario.matricula.trim();
      }
      if (this.usuario.sobrenome) {
        this.usuario.sobrenome = this.usuario.sobrenome.trim();
      }
      if (this.usuario.cargo) {
        this.usuario.cargo = this.usuario.cargo.trim();
      }
      if (this.usuario.documento) {
        this.usuario.documento = this.usuario.documento.trim();
      }
    },
    requestAtualizar(object, resource) {
      resource({ id: object.id }, object)
        .then(() => {
          window.setTimeout(() => {
            this.$router.push({ name: 'usuario-funcionario' });
            this.$toast(this.$t('message.atualizado_com_sucesso'));
          }, 500);
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    requestGravar(object, resource) {
      resource(object)
        .then((res) => {
          this.usuario.id = res.data;
          window.setTimeout(() => {
            this.$router.push({ name: 'usuario-funcionario' });
            this.$toast(this.$t('message.adicionado_confira_tabela'));
          }, 500);
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    carregarUsuario(id) {
      this.usuarioResource.buscar({ id })
        .then((res) => {
          this.editar(res.body);
          forceHideLoading();
        })
        .catch((err) => {
          this.$error(this, err);
          forceHideLoading();
        });
    },
    editar(usuario) {
      this.buscarEstruturasUsuario(null, usuario.idEstruturaUsuario)
        .then(() => {
          this.usuario = new Usuario();
          this.usuario.construirAPartirDaConsultaCompleta({ ...usuario });
          if (this.canEdit && !this.isNovoCadastro && this.usuarioLogadoFuncionario && !this.cadastroFuncionario) {
            this.somenteLeitura = true;
          }
          if (this.usuario.statusFluxo && this.usuario.statusFluxo === 'AGUARDANDO_APROVACAO') {
            this.somenteLeitura = true;
          }
          if (this.usuario.dtaInicioVigencia && this.usuario.dtaFimVigencia) {
            this.dataInicioVigenciaOriginal = this.usuario.dtaInicioVigencia;
            this.dataFimVigenciaOriginal = this.usuario.dtaFimVigencia;
          }

          montarEstruturaDinamica(usuario, this.metadadosUsuario, this.usuario);
          this.usuarioSemEdicao = copyObject(usuario);
        })
        .then(() => this.buscarDivisoes())
        .then(() => this.buscarSuperioresImediatos());
    },
    setDialogContent(content, action, from) {
      this.dialog.content = content || this.$t('message.salvar_novo_registro');
      this.dialog.action = action || this.save;
      this.dialog.from = from || this.dialog.from;
    },
    buscarPerfis(content = '', idPerfil = null) {
      const params = {
        autocomplete: content,
        limit: 1000,
        page: 0,
      };
      if (idPerfil) {
        params.idPerfil = idPerfil;
      }
      return this.perfilResource.selecao(params)
        .then((res) => {
          this.perfis = res.data.filter((perfil) => !this.converterStringParaBoolean(perfil.indPerfilUsuarioExterno));
          if (this.perfis.length === 1) {
            this.usuario.idPerfil = this.perfis[0].id;
          }
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    converterStringParaBoolean(perfil) {
      return (String(perfil).toLowerCase() === 'true');
    },
    triggerEstruturaUsuario() {
      this.triggerClick('usuario-estruturausuario');
    },
    clickEstruturaUsuario() {
      this.buscarEstruturasUsuario(this.searchInputEstrutura);
    },
    buscarEstruturasUsuario(content, idEstruturaUsuario) {
      const param = {
        autocomplete: content,
      };
      if (idEstruturaUsuario) {
        param.id = idEstruturaUsuario;
      }
      return this.estruturaUsuarioResource.selecao(param)
        .then((res) => {
          this.estruturasUsuario = res.data;
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    triggerSuperiorImediato() {
      this.triggerClick('usuario-superioresimediatos');
    },
    clickSuperiorImediato() {
      this.buscarSuperioresImediatos(this.searchInputSuperiorImediato);
    },
    buscarSuperioresImediatos(content = '') {
      const params = {
        nome: content,
        colunaOrdenacao: 'nome_completo',
      };
      if (this.usuario.idEstruturaUsuario) {
        params.idEstruturaUsuario = this.usuario.idEstruturaUsuario;
      }

      return this.usuarioResource.listarSuperior(params)
        .then((res) => {
          this.superioresImediato = res.data.resposta;
          if (this.usuario.superioresImediatos && this.usuario.superioresImediatos.length > 0) {
            this.superioresImediato = [...this.superioresImediato, ...this.usuario.superioresImediatos];
          }
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    triggerDivisao() {
      this.triggerClick('usuario-divisao');
    },
    clickDivisao() {
      this.buscarDivisoes(this.searchInputDivisao);
    },
    divisaoJaInserida(idDivisao) {
      return this.usuario.divisoes
        .filter((divisao) => divisao.id === idDivisao).length > 0;
    },
    buscaDivisaoPorId(id) {
      return this.divisoes.filter((divisao) => divisao.id === id)[0];
    },
    divisaoSelecionada(divisoes) {
      this.usuario.divisoes = [];
      divisoes.forEach((divisao) => {
        if (Object.keys(divisao).length > 0 && !this.divisaoJaInserida(divisao.id)) {
          this.usuario.divisoes.push(divisao);
        } else if (!this.divisaoJaInserida(divisao)) {
          this.usuario.divisoes.push(this.buscaDivisaoPorId(divisao));
        }
      });
      this.usuario.idDivisoes = this.usuario.divisoes.map((divisao) => divisao.id);
    },
    buscarDivisoes(content = null) {
      const params = {
        autocomplete: content,
        cadastroUsuario: true,
      };
      if (this.usuario.idEstruturaUsuario) {
        params.idEstruturaUsuario = this.usuario.idEstruturaUsuario;
      }
      return this.divisaoResource.autocomplete(params)
        .then((res) => {
          this.divisoes = res.data;

          if (this.usuario.divisoes && this.usuario.divisoes.length > 0) {
            this.divisoes = [...this.divisoes, ...this.usuario.divisoes];
          }
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    triggerCliente() {
      this.triggerClick('usuario-cliente');
    },
    clickCliente() {
      this.buscarClientes(this.searchInputCliente);
    },
    buscarClientes(content) {
      const params = {
        autocomplete: content || '',
        limit: 100,
      };
      this.clienteResource.selecao(params)
        .then((res) => {
          this.clientes = res.data;
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    triggerClick(id) {
      document.getElementById(id).click();
    },
    buscarChaveFluxoRepresentanteLegal() {
      this.configSistemaResource.buscarChave({ chave: 'FLUXO_APROVACAO_USR_FORNE_REP_LEG' })
        .then((resp) => {
          this.fluxoAprovacaoRepreLegal = resp.body;
        });
    },
    desabilitarFlagAtivo() {
      return this.somenteLeitura;
    },
    editUsuario(id) {
      this.$router.push({ name: 'editarUsuarioFuncionario', params: { id, ind_novo_usuario: true } });
      window.setTimeout(() => {
        window.location.reload();
      }, 500);
    },
  },
  beforeMount() {
    forceLoading();
    Promise.all([
      this.popularMetadadosUsuario(),
      this.buscarChaveFluxoRepresentanteLegal(),
      this.buscarPerfis(),
    ]).then(() => {});
    if (this.$route.params.id) {
      this.idUsuario = parseInt(this.$route.params.id, 10);
      this.carregarUsuario(this.idUsuario);
    } else {
      forceHideLoading();
    }
  },
  mounted() {
    if (this.usuario.statusFluxo && this.usuario.statusFluxo === 'AGUARDANDO_APROVACAO') {
      this.somenteLeitura = true;
    }
    window.setTimeout(() => {
      if (this.cadastroFuncionario) {
        this.usuario.indAtivo = true;
      }
    }, 500);
  },
};
</script>
